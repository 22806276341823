import React, {useEffect, useState} from 'react'
import {get, post} from "../../../shared/services/ApiService";

import {PageLoader} from "../../common/widgets/PageLoader";
import {useNavigate, useParams} from "react-router-dom";
import {GAME_LOBBY} from "../../../shared/constants/RouteContants";
import {buildModal, useModal} from "../../common/modals/Modal";
import {PropsEditor} from "../../prop/entry/PropsEditor";
import PbButton from "../../common/buttons/PbButton";

export const ResultsEditor = () => {
    const [game, setGame] = useState(null);
    const [loading, setLoading] = useState(true);

    const {setModal} = useModal();

    const navigate = useNavigate();
    const params = useParams();

    const loadData = () => {
        const gameId = params.gameId;
        get(`api/games/${gameId}`).then(game => {
            setGame(game);
            setLoading(false);
        })
    }

    useEffect(() => {
        loadData();
    }, [])

    const saveResults = (completeGame) => {
        const request = {
            propsList: game.props,
            gameId: game.id,
            completeGame: completeGame
        };
        post('api/games/results', request).then(() => {
                const modal = buildModal("Results Submitted", "Results saved successfully.",
                    () => navigate(GAME_LOBBY + game.id));
                setModal(modal);
            },
            error => {
                console.error(error);
                const modal = buildModal("Submission Error", "Please try again. Results couldn't be saved.");
                setModal(modal);
            });
    }



    if (loading) {
        return (<PageLoader></PageLoader>);
    }

    const onPropUpdated = (updatedProp, value) => {
        const newProps = [...game.props];
        newProps.find(p => p.key === updatedProp.key).answer = value
        setGame(prevGame => {
                return {
                    ...prevGame,
                    props: newProps
                }
            }
        );
    }

    return (
        <div className="props-page-body-dark">
            <PropsEditor game={game} onPropUpdated={onPropUpdated}></PropsEditor>
            <div className="pb-btn-row">
                <PbButton label="Save Results" onClick={() => saveResults(false)}></PbButton>
                <PbButton label="Complete Game" onClick={() => saveResults(true)}></PbButton>
            </div>
        </div>
    )
}