import React, {useEffect, useState} from 'react'
import {
    ALL_GAMES,
    GAME_LOBBY,
    OPEN_GAMES,
    START_GAME
} from "../../shared/constants/RouteContants";
import {GameItem} from "../game/common/game-item/GameItem";
import {get} from "../../shared/services/ApiService";
import {PageLoader} from "../common/widgets/PageLoader";
import {useNavigate} from "react-router-dom";
import PbRadio from "../common/buttons/PbRadio";
import PbButton from "../common/buttons/PbButton";

export const DashboardActiveGames = () => {
    const [lockedGames, setLockedGames] = useState(null);
    const [openGames, setOpenGames] = useState(null);
    const [endedGames, setEndedGames] = useState(null);
    const [gameState, setGameState] = useState('PRE_GAME');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const loadData = () => {
        get('api/games?statuses=PRE_GAME,LIVE,POST_GAME').then(games => {
            setOpenGames(games.filter((game) => game.status === 'PRE_GAME'));
            setLockedGames(games.filter((game) => game.status === 'LIVE'));
            setEndedGames(games.filter((game) => game.status === 'POST_GAME'));
            setGameState(games.length > 0 ? games[0].status : 'PRE_GAME');
            setLoading(false);
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    const goToGame = (gameId) => {
        navigate(GAME_LOBBY + gameId);
    }

    const onGameStateUpdated = (newState) => {
        setGameState(newState);
    }

    const getGameSelector = () => {
        const stateOptions = [
            { label: 'PRE-GAME', count: openGames.length , value: "PRE_GAME", games: openGames },
            { label: 'LIVE', count: lockedGames.length, value: "LIVE", games: lockedGames },
            { label: 'POST-GAME', count: endedGames.length, value: "POST_GAME", games: endedGames }
        ]

        const filteredGames = stateOptions.filter((option) => option.value === gameState)[0].games
            .map((game, index) =>
                <GameItem key={index} onClick={goToGame} game={game}></GameItem>
            );

        return (
            <div>
                <PbRadio options={stateOptions} currentValue={gameState} onSelect={onGameStateUpdated}></PbRadio>
                {filteredGames}
                {filteredGames.length === 0 &&
                    <div>You have not entered any games.</div>
                }
            </div>
        )
    }

    return (
        <div className="props-page-body-dark">
            <label className="games-header">My Active Games</label>
            {loading && <PageLoader></PageLoader>}
            {!loading && getGameSelector()}
            <div className="pb-btn-row">
                <PbButton onClick={() => navigate(OPEN_GAMES)} label="Join a game"></PbButton>
                <PbButton onClick={() => navigate(START_GAME)} label="Start a game"></PbButton>
                <PbButton onClick={() => navigate(ALL_GAMES)} label="View all my games"></PbButton>
            </div>
        </div>
    )
}