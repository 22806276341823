import PropTypes from "prop-types";
import React, {useState} from "react";

export const Tooltip = ({text, children}) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);

    const showTooltip = (isVisible) => {
        setTooltipVisible(isVisible);
    };

    return (
        <span className="pb-tooltip"
             onMouseOver={() => showTooltip(true)}
             onMouseOut={() => showTooltip(false)}>
            {text}
            {isTooltipVisible &&
                <div className="pb-tooltip-open">
                    {children}
                </div>
            }
        </span>
    );
}

Tooltip.propTypes = {
    text: PropTypes.any.isRequired
};