import React, {useState} from 'react'
import {post} from "../../../../shared/services/ApiService";
import PropTypes from "prop-types";
import ErrorPanel from "../../../common/error/ErrorPanel";

export const InvitedUsersList = ({game, invitedUsers}) => {
    const [isSaving, setSaving] = useState(false);
    const [errors, setErrors] = useState(null);

    const sendReminder = (invite) => {
        const invites = [
            {
                userId: invite.userId,
                email: invite.email,
                reminder: true
            }
        ];

        setSaving(true);
        post(`api/games/${game.id}/invites`, {invites}).then(() => {
                setSaving(false);
            },
            error => {
                setErrors(error);
                console.error(error);
            });
    }

    const invitedUserRows = invitedUsers.map((invite, index) => {
        let label;
        if (invite.userId) {
            label = `${invite.firstName} ${invite.lastName} (${invite.email})`
        } else {
            label = `${invite.email}`
        }
        const allowReminder = !isSaving && !invite.entered;
        return (
            <div key={index}>
                {label}
                {allowReminder && <a onClick={() => sendReminder(invite)}>&nbsp;<i className="fas fa-bell"></i></a>}
            </div>
        )
    });

    return (
        <div className="invite-container">
            <ErrorPanel errors={errors}></ErrorPanel>
            <div className="invite-columns">
                {invitedUserRows}
            </div>
        </div>
    )
}

InvitedUsersList.propTypes = {
    game: PropTypes.object.isRequired,
    invitedUsers: PropTypes.array.isRequired
}

export default InvitedUsersList;