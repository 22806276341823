import {get, post, put} from "./ApiService";

export const getPropMetadata = (sport, category) => {
    return get(`api/games/prop/metadata?sport=${sport}&category=${category}`)
}

export const saveProp = (prop) => {
    return post('api/games/prop', prop);
}

export const editProp = (propId, prop) => {
    return put(`api/games/prop/${propId}`, prop);
}