import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {post} from '../../../shared/services/ApiService';
import GameScheduleRow from './GameScheduleRow.jsx';
import DateService from '../../../shared/services/DateService';
import {PageLoader} from "../../common/widgets/PageLoader";
import PbContainer from "../../common/containers/PbContainer";
import PbButton from "../../common/buttons/PbButton";

const today = new Date();

const GameSchedule = ({sport, onSelect}) => {
    const [date, setDate] = useState(today);
    const [games, setGames] = useState([]);
    const [gameCount, setGameCount] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        updateSchedule(date);
    }, [date, sport]);

    const updateSchedule = (date) => {
        date.setHours(0, 0, 0, 0);
        const request = {
            date: date.toISOString(),
            sport: sport
        };
        setLoading(true);

        post('api/schedule/get', request).then(schedule => {
            setGames(schedule.games || []);
            setGameCount(schedule.totalGames || 0);
            setLoading(false);
        });
    };

    const addDay = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        setDate(newDate);
    };

    const subtractDay = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);
        setDate(newDate);
    };

    const gameRows = games.map((game) => {
        const onSelectGame = () => {
            onSelect(game);
        }
        return (
            <div className="pb-row" key={game.id}>
                <GameScheduleRow game={game} onSelect={onSelectGame}/>
            </div>
        );
    });

    const currentDate = DateService.parseDate(date, "MMM D, y");
    const allowSubtractDay = date > today;

    const datePicker = (
        <div className="schedule-date-select">
            {allowSubtractDay &&
                <i onClick={subtractDay} className="fas fa-minus-square"></i>
            }
            {currentDate}
            <i onClick={addDay} className="fas fa-plus-square"></i>
        </div>
    );

    return (
        <PbContainer label={`Total Games: ${gameCount}`}
                     actionButton={datePicker}>
            {loading &&
                <PageLoader></PageLoader>
            }
            {!loading &&
                <div className="schedule-body">
                    {gameCount > 0 &&
                        <div>
                            {gameRows}
                        </div>
                    }
                    {gameCount === 0 &&
                            <div className="schedule-no-games">
                                <div>
                                    No games available for date
                                </div>
                                <PbButton label="Create Custom Game" onClick={() => onSelect(null)}></PbButton>
                            </div>

                    }
                </div>
            }
        </PbContainer>
    );
}

GameSchedule.propTypes = {
    sport: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired
}

export default GameSchedule;