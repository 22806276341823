import React, {useState} from 'react'
import PropTypes from "prop-types";
import PbButton from '../../common/buttons/PbButton.jsx';
import LabeledField from '../../common/fields/LabeledField.jsx';
import LabeledDropdown from '../../common/fields/LabeledDropdown.jsx';
import LabeledDateField from '../../common/fields/LabeledDateField.jsx';

import {post} from '../../../shared/services/ApiService';
import {buildModal, useModal} from "../../common/modals/Modal";
import LabeledCheckbox from "../../common/fields/LabeledCheckbox";
import {GAME_LOBBY} from "../../../shared/constants/RouteContants";
import {useNavigate} from "react-router-dom";
import PbContainer from "../../common/containers/PbContainer";

const payoutFormats = ["WINNER_TAKE_ALL", "TOP_2", "TOP_3"];
const adminFeeFormats = ["NONE", "PERCENTAGE", "FIXED"];

const GameDetails = ({game}) => {
    const [newGame, setNewGame] = useState(game);

    const {setModal} = useModal();
    const navigate = useNavigate();

    const onSave = () => {
        post('api/games', newGame).then(result => {
                const modal = buildModal("Game Created", "Add props to your game.");
                setModal(modal);
                navigate(GAME_LOBBY + result.id);
            },
            error => {
                const modal = buildModal("Error", "Failed to create game.");
                setModal(modal);
                console.error(error);
            });
    }

    const onChange = (fieldName, value) => {
        setNewGame(prevGame => {
                return {
                    ...prevGame,
                    [fieldName]: value
                }
            }
        );
    }

    return (
        <PbContainer label="Game Details">
            <div className="game-details">
                <div className="pb-field-row">
                    <LabeledField name="name" label="Game Name" type="text" value={newGame.name}
                                  onChange={onChange}></LabeledField>
                    <LabeledDateField name="startTime" label="Start Time" value={newGame.startTime}
                                      onChange={onChange}></LabeledDateField>
                </div>
                <div className="pb-field-row">
                    <LabeledField name="homeTeam" label="Home Team" type="text" value={newGame.homeTeam}
                                  onChange={onChange}></LabeledField>
                    <LabeledField name="awayTeam" label="Away Team" type="text" value={newGame.awayTeam}
                                  onChange={onChange}></LabeledField>
                </div>
                <div className="pb-field-row">
                    <LabeledField name="cost" label="Entry Cost" type="number" value={newGame.cost}
                                  onChange={onChange}></LabeledField>
                    <LabeledDropdown name="payoutFormat" label="Payout Format" options={payoutFormats}
                                     value={newGame.payoutFormat} onChange={onChange}></LabeledDropdown>
                </div>
                <div className="pb-field-row">
                    <LabeledField name="adminFeeValue" label="Admin Fee" type="number" value={newGame.adminFeeValue}
                                  onChange={onChange}></LabeledField>
                    <LabeledDropdown name="adminFeeFormat" label="Admin Fee Format" options={adminFeeFormats}
                                     value={newGame.adminFeeFormat} onChange={onChange}></LabeledDropdown>
                </div>
                <div className="pb-field-row">
                    <LabeledCheckbox name="addDefaultProps" label="Include Default Props"
                                     value={newGame.addDefaultProps}
                                     onChange={onChange}></LabeledCheckbox>
                </div>
                <div className="pb-btn-row">
                    <PbButton label="Save Game" onClick={onSave} iconClass="fas fa-save"></PbButton>
                </div>
            </div>
        </PbContainer>
    );
}

GameDetails.propTypes = {
    game: PropTypes.object.isRequired
};

export default GameDetails;