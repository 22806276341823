import React, {useEffect, useState} from 'react';
import GameDetails from './GameDetails.jsx';
import GameSchedule from './GameSchedule.jsx';
import SportSelectorModal from './SportSelectorModal.jsx';
import PbButton from '../../common/buttons/PbButton.jsx';
import {useModal} from "../../common/modals/Modal";
import {useNavigate} from "react-router-dom";
import {HOME} from "../../../shared/constants/RouteContants";

export const GameBuilder = () => {
    const [step, setStep] = useState(1);
    const [sport, setSport] = useState(null);
    const [game, setGame] = useState();

    const {setModal} = useModal();
    const navigate = useNavigate();

    const selectSport = () => {
        setModal(<SportSelectorModal currentSport={sport} onSportSelected={(value) => setSport(value)}></SportSelectorModal>);
    }

    useEffect(() => {
        selectSport();
    }, []);

    const onSportSelected = () => {
        if (!sport) {
            return;
        }

        const game = {};
        game.sport = sport;
        game.cost = 5;
        game.addDefaultProps = true;
        game.payoutFormat = "WINNER_TAKE_ALL";
        game.adminFeeValue = 0;
        game.adminFeeFormat = "NONE";
        setGame(game);

        if (sport === "CUSTOM") {
            setStep(3);
        } else {
            setStep(2);
        }
    }

    useEffect(() => {
        onSportSelected();
    }, [sport]);


    const onGameSelected = (externalGame) => {
        if (externalGame) {
            game.startTime = new Date(externalGame.gameDate);
            const home = externalGame.home;
            const away = externalGame.away;
            game.homeTeam = home.nickName || home.fullName;
            game.awayTeam = away.nickName || away.fullName;
            game.name = `${game.awayTeam} vs ${game.homeTeam}`
            game.externalGameId = externalGame.id;
            setGame(prevGame => {
                return {
                    ...prevGame,
                    game
                }
            });
        }

        setStep(3);
    }

    const getCurrentStep = () => {
        if (step === 1) {
            return (
                <div>Select sport to begin</div>
            );
        }

        if (step === 2 && sport) {
            return (
                <GameSchedule onSelect={onGameSelected} sport={sport}></GameSchedule>
            )
        }

        if (step === 3) {
            return (
                <GameDetails game={game}></GameDetails>
            )
        }
    }

    return (
        <div className="props-page-body-dark">
            <label className="games-header">Game Builder</label>
            { getCurrentStep() }
            <div className="pb-btn-row">
                <PbButton label="Change Sport" onClick={selectSport}></PbButton>
                <PbButton label="Cancel" onClick={() => navigate(HOME)}></PbButton>
            </div>
        </div>

    )
}