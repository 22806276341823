import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Sport from '../../../shared/constants/Sport';
import BaseModal from "../../common/modals/BaseModal";
import PbButton from "../../common/buttons/PbButton";
import {closeModal, useModal} from "../../common/modals/Modal";

const sportMap = [
    {
        label: "Hockey",
        type: Sport.HOCKEY
    },
    {
        label: "Football",
        type: Sport.FOOTBALL
    },
    {
        label: "Baseball",
        type: Sport.BASEBALL
    },
    {
        label: "Basketball",
        type: Sport.BASKETBALL
    },
    {
        label: "Custom",
        type: Sport.CUSTOM
    }
];

const SportSelectorModal = ({currentSport, onSportSelected}) => {
    const [sport, setSport] = useState(currentSport);
    const {setModal} = useModal();

    const onSelect = () => {
        onSportSelected(sport);
        closeModal(setModal);
    }

    return (
        <BaseModal title="Select Sport" style="sport-selector">
            <select className="pb-field" defaultValue={currentSport||""} onChange={(e) => setSport(e.target.value)}>
                <option value="" disabled hidden></option>
                {sportMap.map((option, index) => {
                    return <option key={index} value={option.type}>{option.label}</option>;
                })}
            </select>

            <div className="props-modal-btns">
                <PbButton onClick={onSelect} label="OK"></PbButton>
            </div>
        </BaseModal>
    )
}

SportSelectorModal.propTypes = {
    onSportSelected: PropTypes.func.isRequired,
    currentSport: PropTypes.string
}

export default SportSelectorModal;