import React, {useState} from 'react'
import PropTypes from "prop-types";

const FIRST_LEVEL_NAV = 'NAV_1';
export const SECOND_LEVEL_NAV = 'NAV_2';

const PbTabContainer = ({tabs, navLevel = FIRST_LEVEL_NAV}) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const tabBar = tabs.map((tab, index) =>
        <div className={`nav-bar-row-item ${selectedTab === index ? "selected" : ""}`}
             key={index} onClick={() => setSelectedTab(index)}>
            <i className={tab.tabIcon}></i>
            {tab.tabName}
        </div>);

    if (navLevel === FIRST_LEVEL_NAV) {
        return (
            <div className="pb-tab-container">
                <div className="nav-bar-row">{tabBar}</div>
                <div>{tabs[selectedTab].tabContent}</div>
            </div>
        );
    } else {
        return (
            <div>
                <div className="nav-bar-row nav-2">{tabBar}</div>
                <div>{tabs[selectedTab].tabContent}</div>
            </div>
        );
    }


}

PbTabContainer.propTypes = {
    tabs: PropTypes.array.isRequired
};

export default PbTabContainer;