import React from 'react'
import PropTypes from "prop-types";

import DateService from '../../../../shared/services/DateService';
import IconService from '../../../../shared/services/IconService';

import EntrantsTooltip from './EntrantsTooltip.jsx';
import {translate} from "../../../../shared/services/LocalizationService";
import PbContainer from "../../../common/containers/PbContainer";
import {Tooltip} from "../../../common/widgets/Tooltip";

export const GameItem = ({game, onClick, children}) => {
    game.entrants = game.entrants || [];
    const gameDate = DateService.parseDate(game.startTime, 'M/D/YY');
    const startTime = DateService.parseDate(game.startTime, 'h:mm A');
    const sportIcon = IconService.getSportIcon(game.sport);

    const onItemClicked = () => {
        if (onClick) {
            onClick(game.id);
        }
    }

    let homeScore, awayScore;
    if (game.externalGame) {
        homeScore = game.externalGame.boxscore.home.stats.SCORE;
        awayScore = game.externalGame.boxscore.away.stats.SCORE;
    }

    const isClickable = !!onClick;

    let entrantsComponent;
    if (game.entrants.length > 0) {
        entrantsComponent = (
            <Tooltip text="Who's&nbsp;in?">
                <EntrantsTooltip entrants={game.entrants}></EntrantsTooltip>
            </Tooltip>
        );
    }

    let adminFee;
    if (game.adminFeeFormat === 'PERCENTAGE') {
        adminFee = game.adminFeeValue + '%';
    } else if (game.adminFeeFormat === 'AMOUNT') {
        adminFee = '$' + game.adminFeeValue;
    } else {
        adminFee = '$0';
    }

    return (
        <PbContainer label={game.name}
                     iconClass={"category-icon " + sportIcon}
                     actionButton={entrantsComponent}>
            <div className={`game-item ${isClickable ? 'clickable' : ''}`} onClick={onItemClicked}>
                <div className="game-item-date">
                    <div>
                        {gameDate}
                    </div>
                    <div>
                        {startTime}
                    </div>
                </div>
                <div className="game-item-teams">
                    <div className="team-label">{game.awayTeam} <span className="team-score">{awayScore}</span></div>
                    <div className="vs-label">vs.</div>
                    <div className="team-label">{game.homeTeam} <span className="team-score">{homeScore}</span></div>
                </div>
                <div className="game-item-details">
                    <div className="game-detail-row">
                        <b>Payout:</b> {translate(game.payoutFormat)}
                    </div>
                    <div className="game-detail-row">
                        <b>Buy-In:</b> ${game.cost}
                    </div>
                    <div className="game-detail-row">
                        <b>Admin Fee:</b> {adminFee}
                    </div>
                </div>
            </div>
            {children}
        </PbContainer>
    );

};

GameItem.propTypes = {
    game: PropTypes.object.isRequired,
    onClick: PropTypes.func
};