import React, {useEffect, useState} from 'react'

import {GAME_LOBBY, START_GAME} from "../../../shared/constants/RouteContants";
import {GameItem} from "../common/game-item/GameItem";
import {get} from "../../../shared/services/ApiService";
import {useNavigate} from "react-router-dom";
import {PageLoader} from "../../common/widgets/PageLoader";
import PbRadio from "../../common/buttons/PbRadio";

export const MyGames = () => {
    const [games, setGames] = useState(null);
    const [gameState, setGameState] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const goToGame = (game) => {
        navigate(GAME_LOBBY + game.id);
    }

    const getGamesByStatus = (status) => {
        return games.filter(game => game.status === status);
    };

    const loadData = () => {
        get('api/games').then(games => {
            setGames(games);
            setGameState(games.length > 0 ? games[0].status : 'PRE_GAME')
            setLoading(false);
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    if (loading) {
        return (
            <div className="props-page-body-dark">
                <PageLoader></PageLoader>
            </div>
        );
    }

    const onGameStateUpdated = (newState) => {
        setGameState(newState);
    }

    const stateOptions = [
        {label: 'DRAFTS', count: getGamesByStatus("DRAFT").length, value: "DRAFT"},
        {label: 'PRE-GAME', count: getGamesByStatus("ACTIVE").length, value: "ACTIVE"},
        {label: 'LIVE', count: getGamesByStatus("LOCKED").length, value: "LOCKED"},
        {label: 'POST-GAME', count: getGamesByStatus("POST_GAME").length, value: "POST_GAME"},
        {label: 'COMPLETED', count: getGamesByStatus("COMPLETED").length, value: "COMPLETED"}
    ]

    const filteredGames = getGamesByStatus(gameState).map((game, index) =>
        <GameItem key={index} onClick={() => goToGame(game)} game={game}></GameItem>
    );

    return (
        <div className="props-page-body-dark">
            <label className="games-header">My Games</label>
            <PbRadio options={stateOptions} currentValue={gameState} onSelect={onGameStateUpdated}
                     hideEmpty={true}></PbRadio>
            {filteredGames}
            {filteredGames.length === 0 &&
                <div>You haven't entered any games. <a onClick={() => navigate(START_GAME)}>Click here to start a
                    game</a>
                </div>
            }
        </div>
    )
}