import SubmitTextField from "../../common/fields/SubmitTextField";
import React, {useState} from "react";
import {post} from "../../../shared/services/ApiService";
import BasicModal from "../../common/modals/BasicModal";
import {useModal} from "../../common/modals/Modal";
import {validateEmail} from "../../../shared/services/EmailValidator";


const InviteFriendButton = ({game}) => {
    const [isSaving, setSaving] = useState(false);
    const {setModal} = useModal();

    const inviteFriend = (email) => {
        if (!validateEmail(email)) {
            setModal(
                <BasicModal title="Invite Failed"
                            description={`${email} is not a valid email address.`}
                ></BasicModal>
            );
            return;
        }

        const invites = [
            {email: email}
        ];
        setSaving(true);
        post(`api/games/${game.id}/invites`, {invites}).then(() => {
                setSaving(false);
                setModal(
                    <BasicModal title="Invite Sent"
                                description={`Invite sent to ${email} successfully`}
                    ></BasicModal>
                );
            },
            error => {
                setModal(
                    <BasicModal title="Invite Failed"
                                description={`There was an error sending an invite to ${email}`}
                    ></BasicModal>
                );
                console.error(error);
            });
    }

    return (
        <SubmitTextField onSubmit={(email) => inviteFriend(email)}
                         label="Invite Friend"
                         disabled={isSaving}
                         placeholder="Email"></SubmitTextField>
    )
}

export default InviteFriendButton;