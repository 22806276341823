import React, {useEffect, useState} from 'react'

import {GAME_LOBBY} from "../../../shared/constants/RouteContants";
import {GameItem} from "../common/game-item/GameItem";
import {get} from "../../../shared/services/ApiService";
import {PageLoader} from "../../common/widgets/PageLoader";
import {useNavigate} from "react-router-dom";

export const OpenGames = () => {
    const [games, setGames] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const loadData = () => {
        get('api/games/open').then(response => {
            setGames(response);
            setLoading(false);
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    if (loading) {
        return (
            <div className="props-page-body-dark">
                <PageLoader></PageLoader>
            </div>
        );
    }

    const goToGame = (gameId) => {
        navigate(GAME_LOBBY + gameId);
    }

    const openGames = games.map((game, index) =>
        <GameItem key={index} onClick={goToGame} game={game}></GameItem>
    );

    return (
        <div className="props-page-body-dark">
            <div className="games-header">Open Games</div>
            {openGames.length > 0 && openGames}
            {openGames.length === 0 &&
                <div>There are currently no games to join. Want to start your own game? Contact <a>ryan@propsbuddy.com</a> to get started.</div>}
        </div>
    )
}