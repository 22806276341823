import React from 'react'
import PropTypes from "prop-types";
import PbContainer from "../../common/containers/PbContainer";
import {getUserId} from "../../../shared/services/AuthService";

const getWinnerCount = (payoutFormat) => {
    if (payoutFormat === 'TOP_3') {
        return 3;
    } else if (payoutFormat === 'TOP_2') {
        return 2;
    }
    return 1;
}

const Leaderboard = ({game}) => {
    let position = 0;
    let count = 0;
    let currentCorrect = 99999;

    const currentUserId = getUserId();
    const isPreGame = game.status === 'PRE_GAME';

    const entryRows = game.entries
        .sort((a, b) => {
            if (a.amountCorrect > b.amountCorrect) {
                return -1;
            }
            if (a.amountCorrect < b.amountCorrect) {
                return 1;
            }
            return 0;
        })
        .map((entry) => {
            count++;
            if (entry.amountCorrect < currentCorrect) {
                currentCorrect = entry.amountCorrect;
                position = count;
            }

            let rowClass = "results-leaderboard-row";

            if (entry.userId === currentUserId) {
                rowClass += " mine";
            }

            // Only show winner once at least one prop is correct
            const isWinner = currentCorrect > 0 && position <= getWinnerCount(game.payoutFormat);

            return (
                <div className={rowClass} key={entry.userId}>
                    {!isPreGame && <div className="results-leaderboard-position">{position}</div>}
                    <div className="results-leaderboard-status">
                            <span>
                                {isWinner && entry.paid && <i className="fas fa-trophy"></i>}
                                {!entry.paid && <i className="fas fa-search-dollar" title="UNPAID"></i>}
                            </span>
                    </div>
                    <div className="results-leaderboard-name">{entry.firstName} {entry.lastName}</div>
                    {!isPreGame && <div className="results-leaderboard-score">{entry.amountCorrect}</div>}
                </div>
            )
        });

    const entryCount = game.entries.length;

    return (
        <PbContainer label="LEADERBOARD"
                     collapsible={false}>
            <div className="results-leaderboard">
                {isPreGame &&
                    <div className="results-leaderboard-entries-row">
                        Entry Count: {entryCount} (Current Pot ${entryCount * game.cost})
                    </div>
                }
                <div className="results-leaderboard-row">
                    {!isPreGame && <div className="results-leaderboard-position">POS</div>}
                    <div className="results-leaderboard-status"></div>
                    <div className="results-leaderboard-name">NAME</div>
                    {!isPreGame && <div className="results-leaderboard-score">SCORE</div>}
                </div>
                {entryRows}
                {!isPreGame &&
                    <div className="results-leaderboard-entries-row">
                        Entry Count: {entryCount} (Current Pot ${entryCount * game.cost})
                    </div>
                }
                <div className="disclaimer-row">
                    <i className="fas fa-search-dollar"></i><span> - unpaid</span>
                </div>
            </div>
        </PbContainer>
    );
}

Leaderboard.propTypes = {
    game: PropTypes.object.isRequired
};

export default Leaderboard;