import React, {useEffect, useState} from 'react'
import {post} from '../../../shared/services/ApiService';
import {getUserId} from "../../../shared/services/AuthService";
import {PageLoader} from "../../common/widgets/PageLoader";
import {useParams} from "react-router-dom";
import {buildModal, useModal} from "../../common/modals/Modal";
import {PropsEditor} from "./PropsEditor";
import PbButton from "../../common/buttons/PbButton";
import PropTypes from "prop-types";

const PropEntry = ({game}) => {
    const [entry, setEntry] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isDirtyModel, setDirtyModel] = useState(false);
    const params = useParams();

    const {setModal} = useModal();

    const loadData = () => {
        const gameId = params.gameId;
        const vmRequest = {
            userId: getUserId(),
            gameId: gameId
        }
        post("api/viewModel/get", vmRequest).then(response => {
            setEntry(response.viewModelJson || {})
            setLoading(false);
        })
    }

    const onNavigate = (e) => {
        if (!isDirtyModel) {
            return undefined;
        }

        const confirmationMessage = 'Are you sure you want to leave without saving?';
        e.returnValue = confirmationMessage;
        return confirmationMessage;
    };

    useEffect(() => {
        window.addEventListener('beforeunload', onNavigate)
        return () => window.removeEventListener('beforeunload', onNavigate)
    }, [isDirtyModel]);

    const submitProps = () => {
        const saveVmRequest = {
            userId: getUserId(),
            gameId: game.id,
            viewModelJson: entry
        }

        post("api/viewModel/save", saveVmRequest).then(() => {
                setDirtyModel(false);
                const modal = buildModal("Picks Submitted",
                    "Thanks for playing! Your picks have been submitted. You can continue to change your picks until the game has been locked.",
                    () => window.location.reload(), "OK");
                setModal(modal);
            },
            error => {
                console.error(error);
                const modal = buildModal("Submission Error", error);
                setModal(modal);
            })
    }

    const saveProps = () => {
        if (Object.keys(entry).length < game.props.length) {
            const modal = buildModal("Incomplete Picks", "You have not answered all props.",
                () => submitProps(), "Save Anyways", "", "Cancel");
            setModal(modal);
        } else {
            submitProps();
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    if (loading) {
        return (
            <div className="props-page-body-dark">
                <PageLoader></PageLoader>
            </div>
        );
    }

    const onPropUpdated = (updatedProp, value) => {
        setDirtyModel(true);
        setEntry((prevState) => {
            return ({
                ...prevState,
                [updatedProp.key]: value
            })
        });
    }

    return (
        <div>
            <PropsEditor game={game} viewModel={entry} onPropUpdated={onPropUpdated}></PropsEditor>
            <div className="pb-btn-row">
                <PbButton label="Save Props" onClick={saveProps}></PbButton>
            </div>
        </div>
    )
}

PropEntry.propTypes = {
    game: PropTypes.object.isRequired
}

export default PropEntry;

