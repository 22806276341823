import React from 'react'
import PropTypes from "prop-types";

import ResultsTableRow from './ResultsTableRow.jsx';
import PbContainer from "../../../common/containers/PbContainer";
import {getUserId} from "../../../../shared/services/AuthService";

const getName = (entry) => {
    let name = entry.firstName;
    if (entry && entry.lastName) {
        name = name + " " + entry.lastName.charAt(0) + ".";
    }
    return name;
}

export const VIEW_RESOLVED = 'VIEW_RESOLVED';
export const VIEW_UNRESOLVED = 'VIEW_UNRESOLVED';

const ResultsTable = ({viewMode, game}) => {
    const props = game.props.sort((a, b) => {
        if (a.type < b.type) {
            return -1;
        }
        if (a.type > b.type) {
            return 1;
        }
        return 0;
    });

    const currentUserId = getUserId();
    const myPicks = game.entries.find((entry) => entry.userId === currentUserId);
    const otherPicks = game.entries.filter((entry) => entry.userId !== currentUserId)
        .sort((a, b) => {
            if (a.amountCorrect > b.amountCorrect) {
                return -1;
            }
            if (a.amountCorrect < b.amountCorrect) {
                return 1;
            }
            return 0;
        });

    let filteredProps, header;
    if (viewMode === VIEW_RESOLVED) {
        filteredProps = props.filter((prop) => prop.answer);
        header = "RESOLVED PROPS  ";
    } else if (viewMode === VIEW_UNRESOLVED) {
        filteredProps = props.filter((prop) => !prop.answer);
        header = "UNRESOLVED PROPS  ";
    } else {
        filteredProps = props;
        header = "ALL PROPS  ";
    }

    if (filteredProps.length === 0) {
        return <div></div>;
    }

    const getNameScoreLabel = (name, score) => {
        return (
            <div className="results-name-score-label">
                <div className="results-header-name">
                    {name}
                </div>
                <div className="results-header-score">
                    {score}
                </div>
            </div>
        );
    }

    const otherPickNames = otherPicks.map((entry) => {
        return (
            <th className="results-entry-col header" key={entry.userId}>
                {getNameScoreLabel(getName(entry), entry.amountCorrect)}
            </th>
        );
    });

    const otherPickScores = otherPicks.map((entry) => {
        return (
            <th className="results-entry-col" key={entry.userId}>
                {entry.amountCorrect}
            </th>
        );
    });

    const getHeaderRow = () => {
        return (
            <tr className="results-header-row">
                <th className="results-prop-col">Prop</th>
                <th className="results-answer-col">Result</th>
                <th className="results-my-picks-col">
                    {getNameScoreLabel('My Picks', myPicks.amountCorrect)}
                </th>
                {otherPickNames}
            </tr>
        );
    }

    const getScoresRow = () => {
        return (
            <tr className="results-scores-row">
                <th className="results-prop-col">Score</th>
                <th className="results-answer-col"></th>
                <th className="results-my-picks-col">{myPicks.amountCorrect}</th>
                {otherPickScores}
            </tr>
        )
    }

    const rows = filteredProps.map((prop) => {
        return (
            <ResultsTableRow
                key={prop.id}
                prop={prop}
                myPick={myPicks.picks[prop.key]}
                otherPicks={otherPicks}>
            </ResultsTableRow>)
    });

    const showFooterRow = rows.length > 5;

    const headerLabel = `${header} ${filteredProps.length} / ${props.length}`;
    return (
        <div>
            <PbContainer label={headerLabel} collapsible={false}>
                <div className="results-table">
                    <table className="table">
                        <thead>
                        {getHeaderRow()}
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                        <tfoot>
                        {showFooterRow && getScoresRow()}
                        </tfoot>
                    </table>
                </div>
            </PbContainer>
        </div>
    );
}

ResultsTable.propTypes = {
    viewMode: PropTypes.string.isRequired,
    game: PropTypes.object.isRequired
};

export default ResultsTable;