import React, {useEffect, useState} from 'react'
import Leaderboard from './Leaderboard.jsx';
import PbTabContainer from "../../common/containers/PbTabContainer";
import {GameItem} from "../common/game-item/GameItem";
import {useParams} from "react-router-dom";
import {get} from "../../../shared/services/ApiService";
import {PageLoader} from "../../common/widgets/PageLoader";
import {getUserId} from "../../../shared/services/AuthService";
import InviteFriendButton from "./InviteFriendButton";
import PropEntry from "../../prop/entry/PropEntry";
import {GameManager} from "../manage/GameManager";
import MessageDrafter, {MESSAGE_TYPE_ADMIN} from "./MessageDrafter";
import GameInfo from "./GameInfo";
import ResultsTimeline from "./ResultsTimeline";
import ResultsTable, {VIEW_RESOLVED, VIEW_UNRESOLVED} from "./table/ResultsTable";

const LobbyView = () => {
    const [loading, setLoading] = useState(true);
    const [game, setGame] = useState(null);
    const params = useParams();

    const loadData = () => {
        const gameId = params.gameId;
        get(`api/games/${gameId}?attachEntries=true&attachTimeline=true`).then(response => {
            setGame(response);
            setLoading(false);
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    if (loading) {
        return (
            <PageLoader></PageLoader>
        );
    }

    const currentUserId = getUserId();
    const isGameAdmin = currentUserId === game.adminId;
    const isUserEntered = game.entries.filter((entry) => entry.userId === currentUserId).length > 0;

    // If user hasn't entered yet, don't show other lobby options
    if (!isUserEntered && !isGameAdmin) {
        return (
            <div>
                <div className="props-page-body-dark">
                    <GameItem game={game}>
                        <GameInfo game={game}></GameInfo>
                        <PropEntry game={game}></PropEntry>
                    </GameItem>
                </div>
            </div>
        );
    }

    let tabs;

    switch (game.status) {
        case 'PRE_GAME':
            tabs = [
                {tabName: "My Picks", tabIcon: "fas fa-edit",
                    tabContent: <PropEntry game={game}></PropEntry>},
                {tabName: "Details", tabIcon: "fas fa-info-circle",
                    tabContent: <GameInfo game={game}></GameInfo>},
                {tabName: "Who's In", tabIcon: "fas fa-users",
                    tabContent: <Leaderboard game={game}></Leaderboard>},
                {tabName: "Invite", tabIcon: "fas fa-envelope",
                    tabContent: <InviteFriendButton game={game}></InviteFriendButton>},
                {tabName: "Help", tabIcon: "fas fa-question-circle",
                    tabContent: <MessageDrafter game={game} messageType={MESSAGE_TYPE_ADMIN}></MessageDrafter>}
            ];
            break;
        case 'LIVE':
        case 'POST_GAME':
        case 'COMPLETED':
            tabs = [
                {tabName: "Leaderboard", tabIcon: "fas fa-list-ol",
                    tabContent: <Leaderboard game={game}></Leaderboard>},
                {tabName: "Activity", tabIcon: "fas fa-chart-line",
                    tabContent: <ResultsTimeline game={game}></ResultsTimeline>},
                {tabName: "Resolved", tabIcon: "fas fa-check-circle",
                    tabContent: <ResultsTable viewMode={VIEW_RESOLVED} game={game}></ResultsTable>},
                {tabName: "Unresolved", tabIcon: "fas fa-clock",
                    tabContent: <ResultsTable viewMode={VIEW_UNRESOLVED} game={game}></ResultsTable>},
                {tabName: "Help", tabIcon: "fas fa-question-circle",
                    tabContent: <MessageDrafter game={game} messageType={MESSAGE_TYPE_ADMIN}></MessageDrafter>}
            ];
            break;
        default:
            if (isGameAdmin) {
                tabs = [];
                break;
            }
            tabs = [
                {tabName: "Not Available", tabIcon: "fas fa-list-ol",
                    tabContent: <div>Game content not available</div>},
            ]
            break;
    }

    if (isGameAdmin) {
        tabs.push({
                tabName: "Admin", tabIcon: "fas fa-user-cog",
                tabContent: <GameManager game={game}></GameManager>
            }
        );
    }

    return (
        <div className="props-page-body-dark">
            <GameItem game={game}>
                <PbTabContainer tabs={tabs}></PbTabContainer>
            </GameItem>
        </div>
    );
}

export default LobbyView;