import React from 'react'
import {PaymentManagerRow} from "./PaymentManagerRow";

const PaymentManager = ({game}) => {
    const getSortedRows = (filteredEntries) => {
        return filteredEntries.sort((a, b) => {
            if (a.firstName < b.firstName) {
                return -1;
            }
            return 1;
        }).map((entry, index) =>
            <PaymentManagerRow key={index} entry={entry}></PaymentManagerRow>
        );
    }

    const entries = game.entries;

    if (entries.length === 0) {
        return (
            <div className="payment-manager">
                No entries
            </div>
        );
    }

    const unpaidEntryRows = getSortedRows(entries.filter(entry => !entry.paid));
    const paidEntryRows = getSortedRows(entries.filter(entry => entry.paid));


    return (
        <div className="payment-manager">
            <label className="games-header">Unpaid Users</label>
            <div className="payment-manager-group">
                {unpaidEntryRows}
            </div>
            <label className="games-header">Paid Users</label>
            <div className="payment-manager-group paid-entries">
                {paidEntryRows}
            </div>
        </div>
    )
}

export default PaymentManager;