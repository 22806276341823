import React from 'react'
import PropTypes from "prop-types";

const ENTRANTS_TO_SHOW = 15;

const EntrantsTooltip = ({entrants}) => {
    const entrantRows = entrants.slice(0).reverse()
        .map((entrant, index) => {
            if (index > ENTRANTS_TO_SHOW) {
                return;
            } else if (index === ENTRANTS_TO_SHOW) {
                return (
                    <div key={index}>+ {entrants.length - ENTRANTS_TO_SHOW} more</div>
                )
            }
            return (
                <div key={index}>
                    {entrants[index].firstName} {entrants[index].lastName[0]}
                </div>
            );
        })

    return (
        <div className="entrant-tooltip">
            {entrantRows}
        </div>
    )
}

EntrantsTooltip.propTypes = {
    entrants: PropTypes.array.isRequired
};

export default EntrantsTooltip;