import Sport from '../constants/Sport';

const SPORT_ICON = [
    {
        sport: Sport.HOCKEY,
        iconClass: 'fas fa-hockey-puck'
    },
    {
        sport: Sport.FOOTBALL,
        iconClass: 'fas fa-football-ball'
    },
    {
        sport: Sport.BASEBALL,
        iconClass: 'fas fa-baseball-ball'
    },
    {
        sport: Sport.BASKETBALL,
        iconClass: 'fas fa-basketball-ball'
    },
    {
        sport: Sport.CUSTOM,
        iconClass: 'fas fa-pencil-alt'
    }
];

export default class IconService {
    static getSportIcon(sport) {
        var sport = SPORT_ICON.find(item => item.sport === sport);
        return sport ? sport.iconClass : 'fas fa-pencil-alt';
    }
}